#disclaimerFooter {
  display: block;
  z-index: $zindex-sticky;

  width: 100%;

  background-color: $primary;
  color: $secondary;

  padding: 5px 15px;
  text-align: center;

  font-size: 1.2rem;
  line-height: 1.3;
}

#footer {
  border-top: 15px solid $tertiary;

  background-color: $primary;
  padding-top: 5rem;
  padding-bottom: calc(7rem);

}


@include media-breakpoint-up(md) {
  #disclaimerFooter {
    position: fixed;
    bottom: 0;
    left: 0;
    font-size: 1.1rem;
    line-height: 1.3;
  }
}