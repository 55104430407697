@use 'sass:color';

//$nav-link-font-size: 1.2rem;
//$nav-link-font-weight: 200;
//$navbar-padding-x: 0;
//$navbar-padding-y: 0;
//$navbar-brand-padding-y: 1rem;

$nav-link-font-size: 2rem;

$navbar-dark-color: color.adjust($dark, $lightness: 80%);

$navbar-dark-toggler-border-color: transparent;
$navbar-dark-icon-color: $light;

//$navbar-dark-toggler-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-icon-color}' stroke-linecap='square' stroke-miterlimit='10' stroke-width='5' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$navbar-dark-toggler-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='35.935' height='25.156' viewBox='0 0 35.935 25.156'> <g id='Gruppe_893' transform='translate(-318.997 -67.947)'> <path id='Pfad_4619' d='M0,25.155H35.935v-1.8H0ZM0,13.476H35.935v-1.8H0ZM0,1.8H35.935V0H0Z' transform='translate(318.997 67.948)' fill='#fff'/> </g> </svg>");
//$navbar-dark-toggler-icon-bg:      "";
$navbar-toggler-padding-x: 0;
$navbar-toggler-focus-width: 0;

$nav-link-padding-y: 0;
