

a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.text-secondary {
  a {
    color: $secondary;
  }
}
