.breadcrumb {
  padding-top: 3rem;

  .breadcrumb-item {
    text-transform: uppercase;

    padding: 0 var(--#{$prefix}breadcrumb-padding-x) var(--#{$prefix}breadcrumb-padding-y);

    &.active {
      font-weight: 500;
    }
  }

  .breadcrumb-item + .breadcrumb-item::before {
    padding-left: var(--bs-breadcrumb-item-padding-x);
  }
}