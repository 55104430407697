
$carousel-control-color:             $light;

$carousel-control-opacity:           1;
$carousel-control-hover-opacity:     1;
$carousel-control-transition:        opacity .15s ease;

$carousel-control-width:             50%;
$carousel-control-icon-width:        7rem;


$carousel-control-prev-icon-bg: url(buttons/btn_left_light.svg);
$carousel-control-next-icon-bg: url(buttons/btn_right_light.svg);


$carousel-caption-width:             100%;
$carousel-caption-color:             $light;
$carousel-caption-padding-y:         1.25rem;
$carousel-caption-spacer:            14rem;
