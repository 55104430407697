
//$h1-font-size: 12rem;
$h1-font-size: $font-size-root * 4.5;
$h2-font-size: $font-size-root * 3;
$h3-font-size: $font-size-root * 2;
$h4-font-size: $font-size-root * 3;
$h5-font-size: $font-size-root * 1.6;
$h6-font-size: $font-size-root * 1.6;


$font-sizes: (
        1: $h1-font-size,
        2: $h2-font-size,
        3: $h3-font-size,
        4: $h4-font-size,
        5: $h5-font-size,
        6: $h6-font-size
);


$headings-line-height: 1.1;
$headings-font-weight: 300;
$headings-font-family: $font-family-sans-serif-black;
$headings-margin-bottom: 1rem;
