
@each $color, $value in $theme-colors {
  @each $breakpoint, $size in $grid-breakpoints {
    @include media-breakpoint-up($breakpoint) {
      .bg-#{$breakpoint}-#{$color} {
        background-color: $value !important;
      }
    }
  }
}

@each $breakpoint, $size in $grid-breakpoints {
  @include media-breakpoint-up($breakpoint) {
    .bg-#{$breakpoint}-none {
      background-color: transparent !important;
    }
  }
}

.bg-none {
  background-color: transparent !important;
}
