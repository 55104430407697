.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-display-container {
  position: relative;
  overflow: hidden;
  width: 100%;

  .image-display-main {
    //width: auto;
    //height: 400px;
  }

  img {
    height: auto;
    width: 130%;
  }
}

.img-overlay-slope {

  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;

  &:after {
    content: " ";
    position: absolute;
    height: 100%;
    width: 100%;

    left: 20%;
    bottom: 0;

    opacity: 0.4;

    object-position: 0 0;
    object-fit: fill;
    background-image: url("img/img-overlay.svg");
    background-origin: content-box;
    background-position: left bottom;
    background-size: cover;
  }

  &.slope-left:after {
    left: unset;
    right: 40%;
    background-position: right top;
  }

  &.slope-left-wide:after {
    left: unset;
    background-position: right top;
    right: -15%;
    width: 115%;
  }

  &.slope-right-wide:after {
    left: 20%;
  }
}

.efficiency-img {
  max-width: 220px;
}

@include media-breakpoint-up(sm) {

  .img-overlay-slope {

    &.slope-left-wide:after {
      right: -10%;
      width: 110%;
    }
  }

  .image-display-container {

    img {
      width: 100%;
      height: auto;
    }
  }


  .efficiency-img {
    max-width: 270px;
  }
}

@include media-breakpoint-up(md) {

  .image-display-container {

    .image-display-main {
      height: 100vh;
      min-height: 700px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .image-display-caption {
      position: absolute;
      top: 25%;
      left: 0;
      width: 100%;
      //height: 100%;

    }
  }
}


@include media-breakpoint-up(lg) {

  .img-overlay-slope {

    &:after {
      left: 40%;
    }

    &.slope-right-wide:after {
      left: 20%;
    }
  }

}