@use "sass:math";

@mixin skew-overlay() {
  position: relative;
  z-index: 1;
  height: 600px;
  width: 1525px;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: 4;
  }

  clip-path: polygon(0 600px, 317.4px 0, 1525px 0, (1525px - 317.4px) 600px, 0 600px);
}

.skew-overlay {
  @include skew-overlay();
}

.skew-overlay-md {

  margin-bottom: 2rem;

  img {
    height: 200px;
    width: auto;
  }
}


.img-center {
  overflow: visible;
  text-align: center;

  > * {
    position: relative;
    left: 50%;
    transform: translateX(-50%);

    img {
      width: auto;
      height: 200px;
    }

  }
}


@include media-breakpoint-up(sm) {
  .skew-overlay-md {
    img {
      height: 400px;
    }
  }
}

@include media-breakpoint-up(md) {
  .skew-overlay-md {
    margin-bottom: 0;
    height: 400px;
    @include skew-overlay();
  }

  // move image down parallel to an other image
  .img-shift {
    $shiftRange: 300px;
    position: relative;
    bottom: 0; // works together with new height and does not break the flex container
    left: -460px;

    height: 600px + $shiftRange;
  }


  .img-center {
    img {
      width: initial;
      height: initial;
    }
  }

}
