.vehicle-scroll-container {
  .vehicle-card, .vehicle-scroll-wrapper {
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out, scale 0.5s ease-in-out;
  }
  //.vehicle-card {
  //  transform-origin: center center;
  //}
  //.vehicle-scroll-hidden, .vehicle-scroll-future {
  //  opacity: 0;
  //  scale: 0.2;
  //}
}