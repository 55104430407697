.vehicle-card {

  img {
    height: auto;
    //min-height: 360px;
    width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }

  a {
    text-decoration: none;
  }

  .vehicle {
    display: flex;
    flex-direction: column;
    justify-content: stretch;

    .card-body{
      flex-grow: 1;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  ul {
    padding-left: 0;
    column-count: 1;

    li {
      list-style: none;

      margin-left: 5px;

      &:before {
        content: '•';
        margin-right: 5px;
      }
    }
  }

  .card-inner {
    display: inline-block;
    width: 100%;
    margin-top: 1em;
  }

  .bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .price {
      span {
        font-size: 25px;
        font-weight: bold;
        color: $tertiary;
      }
    }

    .cta {
      text-align: end;
      flex-grow: 1;

      span {
        text-align: end;
      }
    }
  }
}


@include media-breakpoint-up(md) {
  .vehicle-card {

    ul {
      column-count: 2;
    }
  }
}