@use "sass:color";

#header {

  .navbar {

    .navbar-collapse {
      justify-content: space-between;
    }

    .navbar-nav {
      margin-top: 20px;
      height: calc(100vh - 125px);
    }

    .nav-link {
      text-transform: uppercase;
    }

    .nav-link-divider {
      //border-top: 1px solid $light;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

}

// submenu handling for mobile

@include media-breakpoint-down(md) {
  #header {
    .navbar {
      z-index: 5;

      .navbar-nav {
        margin-top: 50px;
        padding-left: 75px;

        transition: transform 0.4s ease-in-out;
        transform: translateX(0);
      }

      .navbar-collapse {
        position: relative;
        overflow: hidden;
      }

      .nav-link {
        padding: 1.5rem 0;
      }
    }

    #subNavContainerMobile {
      background-color: transparent;
      position: absolute;
      top: 50px;
      left: 0;
      width: 100%;
      padding-left: 75px;

      transform: translateX(100%);
      transition: transform 0.4s ease-in-out;


      a {
        color: $light;
        text-transform: uppercase;
        padding: 1.5rem 0;
        display: inline-block;
        font-size: 2rem;
      }

      .sub-sub-items {
        position: absolute;
        top: 0;
        left: 100%;
        width: 100%;

        padding-left: 75px;
        display: none;

        &.sub-sub-item-visible {
          display: block;
        }
      }

    }


    &.sub-menu-opened {
      .navbar-nav {
        transform: translateX(-100%);
        transition: transform 0.4s ease-in-out;
      }

      #subNavContainerMobile {
        transform: translateX(0%);
      }
    }

    &.sub-sub-menu-opened {
      #subNavContainerMobile {
        transform: translateX(-100%);
      }
    }

  }
}

// submenu handling for desktop

@include media-breakpoint-up(md) {

  #header {

    .navbar {

      .navbar-collapse {
        justify-content: space-between;
      }

      .navbar-nav {
        margin-top: 0;
        align-items: center;
        height: initial;

        .nav-link {
          font-size: 1.4rem;
          font-weight: 300;
          text-transform: uppercase;
          margin-right: 5px;
          position: relative;

          padding-top: 0.5em;
          padding-bottom: 0.5em;

          text-decoration: none;

          &:after {
            content: "";
            border-bottom: 1px solid $light;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0;

            transition: width 0.3s ease-in-out;


            // line should only be as wide as the text
            margin-right: var(--bs-navbar-nav-link-padding-x);
            margin-left: var(--bs-navbar-nav-link-padding-x);
          }

          &:hover:after, &.active:after {
            // line should only be as wide as the text
            width: calc(100% - var(--bs-navbar-nav-link-padding-x) * 2);
          }
        }

        .nav-link-divider {
          border-top: none;
          position: relative;
          margin-right: 5px;

          &:after {
            content: "";
            border-right: 1px solid $light;
            position: absolute;
            top: -1em;
            height: 2em;

          }
        }
      }
    }


    #subNavContainer {
      background-color: color.adjust($dark, $alpha: -0.2);
      position: relative;
      top: 0;
      right: unset;

      padding: 0 3rem 6rem 115px;


      a {
        color: $light;
        text-transform: uppercase;
        padding: 1.5rem 0;
        display: inline-block;
      }


      .sub-item {
        transition: color 0.4s ease-in-out;

        .sub-sub-items {
          position: absolute;
          padding: 0;
          top: 0;
          left: 250px;
          height: 100%;
          opacity: 0;

          // needed to avoid overlapping of inactive items (display:none would destroy the opacity transition)
          transform: translateX(100%);

          //transition: opacity 0.4s ease-in-out;
          transition: opacity 0.4s ease-in-out, transform 0s linear 0.4s;

        }


        &:hover, &.active {
          a {
            color: $secondary;
          }

          .sub-sub-items {
            display: block;
            opacity: 1;
            transform: translateX(0);
            transition: opacity 0.4s ease-in-out, transform 0s linear 0s;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(in) {
  #header {
    .navbar {

      .navbar-nav {
        .nav-link {
          font-size: 1.5rem;
          margin-right: 20px;
        }

        .nav-link-divider {
          margin-right: 20px;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  #header {
    .navbar {
      .navbar-nav {
        .nav-link {
        }

        .nav-link-divider {
          margin-right: 20px;
        }
      }
    }
  }
}
