@use "sass:math";

.container-fluid {
  padding-right: 0;
  padding-left: 0;
  overflow: hidden;
}

.container {
  // container shall always be at least 23px from the left and 23px from the right (other screen sized see below)
  padding-right: 23px + math.div($grid-gutter-width, 2);
  padding-left: 23px + math.div($grid-gutter-width, 2);
}

.container-slope-top {
  width: 100%;
  margin: 0;
  padding: 0;
  border: none;
  overflow: hidden;

  img {
    // there seems to be a slight gap at the left side of the image; this solves it
    position: relative;
    left: -1%;
    width: 101%;

    // some browser habe a light line beneath the slope
    margin: 0 0 -1px 0;

    height: auto;
    padding: 0;
    border: none;
    display: block;

    .slope-path {
      fill: #000;
      stroke-width: 0;
    }

  }
}

@include media-breakpoint-up(sm) {
  .container {
    padding-right: 35px + math.div($grid-gutter-width, 2);
    padding-left: 35px + math.div($grid-gutter-width, 2);
  }
}

@include media-breakpoint-up(md) {
  .container {
    padding-right: calc(5% + math.div($grid-gutter-width, 2));
    padding-left: calc(5% + math.div($grid-gutter-width, 2));
    //padding-right: 140px + math.div($grid-gutter-width, 2);
    //padding-left: 140px + math.div($grid-gutter-width, 2);
  }
}