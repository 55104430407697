.media-header, .image-header {
  width: 100vw;
  height: 100vh;

  min-height: 700px;
  position: relative;

  video, img, picture, source {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .media-header-content, .image-header-content {
    position: absolute;
    left: 0;
    top: 25%;
    padding: 0;

    color: $light;
    overflow: hidden;
    width: 100%;

    h1 {
      //font-size: 4.5rem;
      //line-height: 1.1;
      font-weight: 700;
      text-transform: uppercase;
    }

    h2 {
      //font-size: 3rem;
      //line-height: 1.3;
      font-weight: 300;
      text-transform: uppercase;
      margin-bottom: 10px;
    }

    .btn {
      font-size: 2.4rem;
    }
  }

  .image-header-content {
    width: 100%;
  }

  .model-image-header-content {
    position: absolute;
    left: 0;
    top: 25%;
    width: 100%;
  }

  .model-image-bottom-content {
    position: absolute;
    left: 0;
    bottom: 40px;
    width: 100%;
  }

  .scroll-hint-icon {
    position: absolute;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
  }
}

//
//@include media-breakpoint-up(md) {
//
//  .media-header, .image-header {
//    .media-header-content, .image-header-content {
//
//      h1 {
//        //font-size: 10rem;
//        //line-height: 1.2;
//        font-weight: 700;
//        text-transform: uppercase;
//      }
//
//      h2 {
//        //font-size: 2rem;
//        //font-size: 4rem;
//        //line-height: 1.2;
//        font-weight: 300;
//        text-transform: uppercase;
//        margin-bottom: 10px;
//      }
//    }
//  }
//}