@use "sass:math";

.overflow-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  overflow: visible;

  & > * {
    flex: 1 0 auto;
  }
}

.overflow-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  overflow: visible;

  text-align: right;

  & > * {
    flex: 1 0 auto;
  }
}


@include media-breakpoint-up(sm) {
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
}

@include media-breakpoint-up(md) {

  .w-md-75 {
    width: 75% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
}

@include media-breakpoint-up(lg) {
  .lg-columns-2 {
    column-count: 2;
  }

  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
}
