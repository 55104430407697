#vehicleGalleryCarousel {
  .carousel-inner {
      height: auto;
  }
  .carousel-item {
    img {
      width: 100%;
      height: auto;
    }
  }

  .carousel-control-next, .carousel-control-prev {

    align-items: end;
    z-index: 5;

    .carousel-control-prev-icon, .carousel-control-next-icon {
      margin: 20px;
    }

  }

  .carousel-control-next:hover {
    .carousel-control-next-icon {
      background-image: url(buttons/btn_right_light_i.svg);
    }
  }

  .carousel-control-prev:hover {
    .carousel-control-prev-icon {
      background-image: url(buttons/btn_left_light_i.svg);
    }
  }

}


.carousel-selector-container {
  width: 100%;
  overflow: hidden;
  overflow-x: auto;

  ul {
    display: flex;
    margin: 0;
    padding: 5px 0;
    column-count: 1;
    height: 90px;
    list-style-type: none;
    overflow-x: auto;

    li {
      display: inline-block;
      //width: 95px;
      height: 75px;
      margin: 0 2px;

      cursor: pointer;

      img {
        width: auto;
        height: 100%;
        object-fit: cover;
        opacity: .4;
        transition: opacity .4s;

        &:hover {
          opacity: .7;
        }
      }

      &.active {
        img {
          opacity: 1;
        }
      }
    }
  }
}