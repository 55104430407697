// ------------ overwrite bootstrap variables -----------------------------------


// ----- colors ------
$primary: #000000;
$secondary: #ffffff;

$tertiary: #917300;
$quaternary: #9173001A;

$light: #F5F5F5;
$dark: #181818;


$red:     #dc3545;
$yellow:  #ffc107;
$green:   #198754;

$success:       $green;
$info:          $tertiary;
$warning:       $yellow;
$danger:        $red;


$body-bg: $secondary;
$body-color: $dark;

$theme-colors: (
        "primary":    $primary,
        "secondary":  $secondary,
        "tertiary":   $tertiary,
        "quaternary":   $quaternary,
        "success":    $success,
        "info":       $info,
        "warning":    $warning,
        "danger":     $danger,
        "light":      $light,
        "dark":       $dark
);


// used for automatic color determination (eg. font color on buttons)
// see @function color-contrast in node_modules/bootstrap/scss/_functions.scss
// used in @mixin button-variant in node_modules/bootstrap/scss/mixins/_buttons.scss
$min-contrast-ratio: 2;

  // ----- font ------

$font-family-base: 'Roboto', 'Open Sans', Arial, sans-serif;

$font-family-sans-serif: 'Roboto', 'Open Sans', Arial, sans-serif;
$font-family-sans-serif-extended: 'Roboto', 'Open Sans', Arial, sans-serif;
$font-family-sans-serif-bold: 'Roboto', 'Open Sans', Arial, sans-serif;
$font-family-sans-serif-black: 'Roboto', 'Open Sans', Arial, sans-serif;
$font-family-sans-serif-heavy: 'Roboto', 'Open Sans', Arial, sans-serif;
$font-family-monospace: "Liberation Mono", "Courier New", monospace;

$font-size-root: 10px;
$font-size-base: 1.6rem;

$line-height-base:            1.6;
$line-height-sm:              1.3;
$line-height-lg:              2;

$font-weight-lighter:         100;
$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-medium:          500;
$font-weight-semibold:        700;
$font-weight-bold:            700;
$font-weight-bolder:          900;

$font-weight-base: $font-weight-light;

$enable-responsive-font-sizes: false;


$zindex-fulloverlay: 1100;

// ----- dimensions ------
$grid-gutter-width: 10px;

$grid-breakpoints: (
        xs: 0,
        sm: 760px,
        md: 1024px,
        in: 1200px, // special breakpoint to handle navi
        lg: 1460px,
        //xl: 1920px,
) !default;

// grid container:

$container-max-widths: (
        xs: (760px - 46px),
        sm: (1024px - 70px),
        md: (1460px - 100px),
        in: 1640px,
        //lg: 1640px,
        //xl: 1640px,
);

// Breakpoint at where values start decreasing if screen width is smaller
$enable-rfs: false;
$rfs-breakpoint: 760px;
$rfs-breakpoint-unit: px;


@import "variables/base";
@import "variables/navbar";
@import "variables/breadcrumbs";
@import "variables/headings";
//@import "variables/buttons";
//@import "variables/card";
//@import "variables/accordion";
//@import "variables/forms";
@import "variables/table";
@import "variables/carousel";
