@use "sass:math";

@import "bootstrap-carousel-vehicles";

.carousel-inner {
  width: 100%;
  height: 100vh;

  overflow: hidden;

  position: relative;
}

.carousel-item {
  position: relative;
  overflow: hidden;

  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transition: transform 0.75s ease, opacity 0.75s ease;
  }

  &.active.carousel-item-end, &.active.carousel-item-start {
    // keep active image in place during animation
    transform: translateX(0);
    z-index: 1;

    img {
      opacity: 1;
    }
  }

  &:not(.active).carousel-item-end, &:not(.active).carousel-item-start {
    z-index: 2;
    img {
      opacity: 1;
    }
  }


  .img-overlay-slope {
    transform: translateX(-100%);
    transition: transform 0.75s ease;
  }

  .carousel-caption {
    transform: translateX(-100%);
    opacity: 0;
    transition: transform 0.75s ease, opacity 0.75s ease;
    transition-delay: 300ms;

    .carousel-cta {
      opacity: 0;
      transition: transform 0.75s ease, opacity 0.75s ease;
      transition-delay: 1000ms;
    }
  }

  &.active {
    img {
      opacity: 1;
    }

    .img-overlay-slope {
      transform: translateX(0);
    }

    .carousel-caption {
      transform: translateX(0);
      opacity: 1;

      .carousel-cta {
        opacity: 1;
      }

    }
  }
}

.carousel-controls {
  position: absolute;
  z-index: 100;

  left: 23px + math.div($grid-gutter-width, 2);
  //left: 10px;
  top: 100px;
  min-width: 190px;
  min-height: 150px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  .carousel-control-prev {
    justify-content: flex-start;
  }
  .carousel-control-next {
    justify-content: flex-end;

  }

  .carousel-control-next:hover {
    .carousel-control-next-icon {
      background-image: url(buttons/btn_right_light_i.svg);
    }
  }

  .carousel-control-prev:hover {
    .carousel-control-prev-icon {
      background-image: url(buttons/btn_left_light_i.svg);
    }
  }

  .text-light, .text-dark {
    font-size: 1.5em;
    font-weight: 300;
    line-height: 1;
  }

}

.carousel-caption {
  bottom: unset;
  top: 290px;

  text-align: left;
  //padding-left: 25px;
  //padding-right: 25px;

  .carousel-cta {
    text-align: right;

    position: relative;
    top: -100px;
    //right: 23px + math.div($grid-gutter-width, 2);
  }

  //h1 {
  //  font-size: 4.5rem;
  //}
}

@include media-breakpoint-up(md) {

  .carousel-controls {

    left: 140px;
    top: 25%;
    min-width: 190px;
    min-height: 230px;
    justify-content: flex-start;

    .text-light, .text-dark {
      font-size: 2.6em;
    }
  }


  .carousel-caption {
    bottom: unset;
    top: 25%;
    left: 0;

    max-height: 75%;
    width: 100%;

    overflow: hidden;
    //text-align: left;
    //padding-top: 0;
    //padding-left: 25px;
    //padding-right: 25px;

    h1 {
      font-size: 7.5rem;
      line-height: 1;
      margin-bottom: 0;
    }

    h2 {
      font-weight: 400;
    }

    .carousel-cta {
      text-align: right;
      position: unset;
    }
  }

}


@include media-breakpoint-up(lg) {

  .carousel-caption {

    h1 {
      font-size: 12rem;
    }

  }
}