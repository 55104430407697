@use "sass:color";

#header {
  margin: 0;
  padding: 0;

  .navbar {
    padding: 10px 25px;
    background-color: color.adjust($dark, $alpha: -0.2);
    border-top: 1px solid $light;

    transition: background-color 0.4s ease-in-out;

    .navbar-brand {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      font-size: 1.8rem;
      text-transform: uppercase;

      &:hover {
        text-decoration: none;
      }

      img {
        height: 60px;
      }

      span {
        margin: 0 10px 0 25px;
        font-weight: bold;

        width: 150px;
        white-space: initial; // allow wrap
        line-height: 1.1;
      }
    }

  }
}

#header-contact {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  transition: width 0.3s ease-in-out;

  #header-contact-container {
    width: 100%;
    padding: 3px 0 3px 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    font-size: 1.5rem;
    line-height: 1.2;

    span {
      margin-right: 25px;
    }
  }
}


@include media-breakpoint-down(md) {

  #header.menu-opened {
    .navbar {
      background-color: color.adjust($dark, $alpha: -0.1);
    }
  }
}


@include media-breakpoint-up(sm) {

  #header-contact {

    #header-contact-container {
      width: 50%;
    }
  }
}

@include media-breakpoint-up(md) {

  #header {
    margin: 0;
    padding: 0 25px;

    .navbar {
      padding: 20px 25px;

      .navbar-brand {
        margin-right: 35px;
        text-decoration: none;
        color: $light;

        img {
          height: 60px;
        }

        a {
          color: $light;
          text-decoration: none;
        }
      }

      .navbar-brand-end {
        //width: 180px;
        font-size: 1.8rem;
        text-transform: uppercase;
        text-decoration: none;
        margin: 0;

        span {
          width: 125px;
          margin: 0;
        }
      }
    }
  }

  #header-contact {

    #header-contact-container {
      width: auto;
    }
  }


}


@include media-breakpoint-up(in) {
  #header {
    .navbar {
      .navbar-brand {

        img {
          height: 60px;
        }
      }

      .navbar-brand-end {
        font-size: 2.2rem;
        width: 150px;
      }
    }
  }
}

