
.btn {
  &.btn-dyn-icon {
    height: 55px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;

    min-width: 280px;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .btn-icon-hover {
      display: none;
  }

  &:hover, &:active, &:focus-visible {
    .btn-icon-hover {
      display: inline;
    }
    .btn-icon-no-hover {
      display: none;
    }
  }
}

.btn-icon  {
  padding: 0 0 0 85px;
  background-color: transparent;
  border-color: transparent;
  height: 75px;
  position: relative;
  font-size: 2.4rem;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  text-decoration: none;
  text-transform: uppercase;

  &:hover, &:active {
    background-color: transparent !important;
    border-color: transparent !important;
    text-decoration: none;

    opacity: 0.7;
  }

  &.disabled {
    background-color: transparent !important;
    border-color: transparent !important;
    opacity: 0.2;
  }


  &.btn-vertical {
    flex-direction: column;
    padding-left: 0;
    padding-top: 85px;
    height: auto;
    width: 75px;
    overflow: visible;
    text-align: right;
    line-height: 1.2;
    align-items: flex-end;
  }

  &.btn-primary {
    color: $primary;
  }
  &.btn-secondary {
    color: $secondary;
  }
  &.btn-tertiary {
    color: $tertiary;
  }
  &.btn-light {
    color: $light;
  }
  &.btn-dark {
    color: $dark;
  }

  &:before, &:after {
    content: url(buttons/btn_right_light.svg);
    width: 60px;
    height: 70px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 1;
    transition: opacity 0.4s ease;
  }
  &:after {
    content: url(buttons/btn_right_light_i.svg);
    opacity: 0;
  }

  &:hover, &:active {
    &:before {
      opacity: 0;
    }
    &:after {
      opacity: 1;
    }
  }

  &.btn-dark:before {
    content: url(buttons/btn_right_dark.svg);
  }
  &.btn-dark:after {
    content: url(buttons/btn_right_dark_i.svg);
  }

  &.btn-icon-end {
    padding-left: 0;
    padding-right: 85px;
    text-align: end;
    line-height: 1.2;

    &:before, &:after {
      left: unset;
      right: 0;
    }
  }
}

.btn-icon-left {
  &:before {
    content: url(buttons/btn_left_light.svg);
  }
  &:after {
    content: url(buttons/btn_left_light_i.svg);
  }
  &.btn-dark:before {
    content: url(buttons/btn_left_dark.svg);
  }
  &.btn-dark:after {
    content: url(buttons/btn_left_dark_i.svg);
  }
}
.btn-icon-down {
  &:before {
    content: url(buttons/btn_down_light.svg);
  }
  &:after {
    content: url(buttons/btn_down_light_i.svg);
  }
  &.btn-dark:before {
    content: url(buttons/btn_down_dark.svg);
  }
  &.btn-dark:after {
    content: url(buttons/btn_down_dark_i.svg);
  }
}
.btn-icon-plus {
  &:before {
    content: url(buttons/btn_plus_light.svg);
  }
  &:after {
    content: url(buttons/btn_plus_light_i.svg);
  }
  &.btn-dark:before {
    content: url(buttons/btn_plus_dark.svg);
  }
  &.btn-dark:after {
    content: url(buttons/btn_plus_dark_i.svg);
  }
}