h1 {
  font-size: 4.5rem; // override bootstrap's rfs feature
  line-height: 1.2;
  font-weight: 500;
  text-transform: uppercase;
}

h2 {
  font-size: 3rem; // override bootstrap's rfs feature
  line-height: 1.3;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

h3 {
  font-size: $h3-font-size; // override bootstrap's rfs feature
  line-height: 2.8rem;
  font-weight: bold;
}

h4 {
  font-size: $h4-font-size; // override bootstrap's rfs feature
  line-height: 3.6rem;
}

.h2 {
  @extend h2;
  margin-bottom: 0;
}
.h3 {
  @extend h3;
  margin-bottom: 0;
}
.h4 {
  @extend h4;
  margin-bottom: 0;
}


@include media-breakpoint-up(sm) {
  h1 {
    font-size: 8rem;
    line-height: 1;
  }
  h2 {
    font-size: 4rem;
    line-height: 1.2;
  }
}

@include media-breakpoint-up(md) {
  h1 {
    font-size: 8rem;
    line-height: 1;
  }

  h2 {
    font-size: 4rem;
    line-height: 1.1;
  }

}

@include media-breakpoint-up(lg) {
  h1 {
    font-size: 10rem;
    line-height: 1;
  }

  h2 {
    font-size: 4rem;
    line-height: 1.2;
  }

}