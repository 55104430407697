// ------ spacers ----------

// scss-docs-start spacer-variables-maps
$spacer: 1rem !default;
$spacers: (
        0: 0,
        1: $spacer * .25,
        2: $spacer * .5,
        3: $spacer,
        4: $spacer * 2,
        5: $spacer * 3,
        6: $spacer * 4,
        7: $spacer * 5,
        8: $spacer * 6,
        extreme: $spacer * 12,
) !default;
// scss-docs-end spacer-variables-maps


// ------- links --------
$link-color: $primary;
$link-decoration: underline;


// ------- paragraphs -----
$paragraph-margin-bottom: 2rem;

// ----- border ------
$border-radius: 0;
$border-radius-lg: 0;
$border-radius-sm: 0;

$border-width: 2px;

// ------- modal ------
$modal-backdrop-opacity: 0.7;
